body {
    margin: 0;
}

@font-face {
    font-family: "Sriracha Regular";
    src: url("../fonts/beingmekc/Sriracha/Sriracha-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Kalam Bold";
    src: url("../fonts/beingmekc/Kalam/Kalam-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Kalam Regular";
    src: url("../fonts/beingmekc/Kalam/Kalam-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Kalam Light";
    src: url("../fonts/beingmekc/Kalam/Kalam-Light.ttf") format("truetype");
}

.d-container {
    position: relative;
    z-index: 1;
}
  
  .d-top-left {
    position: absolute;
    top: 10%;
    left: 10%;
    z-index: 3;
  }

  .d-top-right {
    position: absolute;
    top: 10%;
    right: 10%;
    z-index: 3;
  }

  .d-bottom-right {
    position: absolute;
    bottom: 10%;
    right: 10%;
    z-index: 3;
  }

  .d-bottom-left {
    position: absolute;
    bottom: 10%;
    left: 10%;
    z-index: 3;
  }
  
  .bg-img { 
    width: 100%;
    height: auto;
    position: relative;
    z-index: -1;
  }

  .vignette {
    position: absolute;
    height: 100%;
    width: 100%;
    /* box-shadow: inset 0 0 300px 100px white; */
    
    box-shadow: inset 0 0 35px 30px white, inset 0 0 100px 100px white;
    z-index: 2;
  }